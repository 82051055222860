import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import { leapfrog } from "ldrs";
leapfrog.register();

const LandingPage = () => {
    return (
        <div className="landing-container main-container">
            <h1>Landing Page</h1>
        </div>
    );
}   
export default LandingPage;